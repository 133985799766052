import React from "react";
import Slider from "react-slick";

import {
    logo,
      ajaxLoader,
      fav,
      schtech,
      lpBanner,
      bgFirst,
      bgSecond,
      bgThird,
      bgFourth,
      bgFive,
      booksFirst,
      booksSecond,
      booksThird,
      booksFourth,
      booksFive,
      booksSix,
      booksSeven,
      booksEight,
      booksNine,
      booksTen,
      booksEleven,
      booksTwelve,
      booksThirteen,
      booksFourteen,
      booksBg,
      brandsFirst,
      brandsSecond,
      brandsThird,
      brandsFourth,
      categoriesFirst,
      categoriesSecond,
      categoriesThird,
      categoriesFourth,
      categoriesFive,
      categoriesSix,
      categoriesSeven,
      categoriesEight,
      categoriesNine,
      categoriesTen,
      categoriesEleven,
      categoriesTwelve,
      categoriesThirteen,
      categoriesFourteen,
      categoriesFifteen,
      categoriesSixteen,
      categoriesSeventeen,
      categoriesEighteen,
      categoriesNinteen,
      categoriesTwenty,
      iconsFourth,
      iconsFive,
      iconsSix,
      iconsSeven,
      iconsSeventyOne,
      iconsSeventyTwo,
      iconsSeventyThree,
      iconsSeventyFourth,
      iconsSeventyFifth,
      iconsSeventySixth,
      iconsSeventySeventh,
      logoFirst,
      logoSecond,
      logoThird,
      logoFourth,
      logoFive,
      logoSix,
      logoSeven,
      logoEight,
      mixFirst,
      mixSecond,
      mixFourth,
      mixUserFirst,
      mixUserSecond,
      mixUserThird,
      mixUserFourth,
      mixUserFive,
      mixUserSix,
      platformNine,
      platformTen,
      platformEleven,
      platformTwelve,
      platformThirteen,
      platformFourteen,
      teamFirst,
      teamSecond,
      teamThird,
      teamFourth,
      teamFive,
      teamSix,
      teamSeven,
      teamEight,
      teamNine,
      teamTen,
      teamEleven,
      teamTwelve,
      teamThirteen,
      teamFourteen,
      teamFifteen,
      teamSixteen,
      teamSeventeen,
      teamEighteen,
      teamNinteen,
      teamTwenty,
  } from '../assets/images';


export default function ReviewSlider() {
  var settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
  };
  return (
    <Slider {...settings}>
        <div>
            <div class="reviewBox">
                <div class="reviewHead">
                    <img loading="lazy" src={mixUserFirst} alt="image" width="75"
                        height="75"/>
                    <h3>Ann R. Hensley</h3>
                </div>
                <p>
                    As someone whose mind is always buzzing with thoughts, putting them down on paper
                    has
                    been a bit tricky for me. However, the team's support has been beyond amazing. Their
                    professionalism has shone through at every stage, and I genuinely feel I can trust
                    them
                    for my future book writing endeavors.
                </p>
            </div>
        </div>

        <div>
            <div class="reviewBox">
                <div class="reviewHead">
                    <img loading="lazy" src={mixUserSecond} alt="image" width="75"
                        height="75"/>
                    <h3>Emily Delgado</h3>
                </div>
                <p>The Amazon Kindle Publisher truly delivered for me when it came to crafting my
                    first
                    book.
                    They quickly made the changes I requested and kept me in the loop at every step.
                    Choosing them for my debut book was a great decision, and I'm genuinely thrilled
                    with
                    the results!
                </p>
            </div>
        </div>

        <div>
            <div class="reviewBox">
                <div class="reviewHead">
                    <img loading="lazy" src={mixUserThird} alt="image" width="75"
                        height="75"/>
                    <h3>Margaret Brown</h3>
                </div>
                <p>I've been collaborating with Amazon Kindle Publisher for a year and have already published one successful
                    book with them. Even though there's more ground to cover with additional projects in
                    the
                    pipeline, I have full confidence in Amazon Kindle Publisher.</p>
            </div>
        </div>

        <div>
            <div class="reviewBox">
                <div class="reviewHead">
                    <img loading="lazy" src={mixUserFourth} alt="image" width="75"
                        height="75"/>
                    <h3>Thomas Bower</h3>
                </div>
                <p>
                    Amazon Kindle Publisher is the go-to place for anyone looking to kickstart their
                    book
                    publishing journey on various platforms, including KDP. They assisted me in getting
                    my
                    book out there for a reasonable fee. I highly recommend them.
                </p>
            </div>
        </div>

        <div>
            <div class="reviewBox">
                <div class="reviewHead">
                    <img loading="lazy" src={mixUserFive} alt="image" width="75"
                        height="75"/>
                    <h3>Wilma Ford</h3>
                </div>
                <p>I'm truly grateful for Sarah's understanding of my publishing needs and her efforts
                    to
                    elevate the quality of my book. Thank you for turning my dream of becoming a
                    published
                    author into a reality.
                </p>
            </div>
        </div>
        <div>
            <div class="reviewBox">
                <div class="reviewHead">
                    <img loading="lazy" src={mixUserSix} alt="image" width="75"
                        height="75"/>
                    <h3>Paul Giles</h3>
                </div>
                <p>I'm thrilled with Amazon Kindle Publisher and the fantastic job their team did
                    with my
                    book
                    cover. They went above and beyond my expectations, creating an incredibly artistic
                    and
                    beautiful design. Everyone who has seen the cover is impressed!
                </p>
            </div>
        </div>
    </Slider>
  );
}