import './App.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    logo,
    ajaxLoader,
    fav,
    schtech,
    lpBanner,
    bgFirst,
    bgSecond,
    bgThird,
    bgFourth,
    bgFive,
    booksFirst,
    booksSecond,
    booksThird,
    booksFourth,
    booksFive,
    booksSix,
    booksSeven,
    booksEight,
    booksNine,
    booksTen,
    booksEleven,
    booksTwelve,
    booksThirteen,
    booksFourteen,
    booksBg,
    brandsFirst,
    brandsSecond,
    brandsThird,
    brandsFourth,
    categoriesFirst,
    categoriesSecond,
    categoriesThird,
    categoriesFourth,
    categoriesFive,
    categoriesSix,
    categoriesSeven,
    categoriesEight,
    categoriesNine,
    categoriesTen,
    categoriesEleven,
    categoriesTwelve,
    categoriesThirteen,
    categoriesFourteen,
    categoriesFifteen,
    categoriesSixteen,
    categoriesSeventeen,
    categoriesEighteen,
    categoriesNinteen,
    categoriesTwenty,
    iconsFourth,
    iconsFive,
    iconsSix,
    iconsSeven,
    iconsSeventyOne,
    iconsSeventyTwo,
    iconsSeventyThree,
    iconsSeventyFourth,
    iconsSeventyFifth,
    iconsSeventySixth,
    iconsSeventySeventh,
    logoFirst,
    logoSecond,
    logoThird,
    logoFourth,
    logoFive,
    logoSix,
    logoSeven,
    logoEight,
    mixFirst,
    mixSecond,
    mixFourth,
    mixUserFirst,
    mixUserSecond,
    mixUserThird,
    mixUserFourth,
    mixUserFive,
    mixUserSix,
    platformNine,
    platformTen,
    platformEleven,
    platformTwelve,
    platformThirteen,
    platformFourteen,
    teamFirst,
    teamSecond,
    teamThird,
    teamFourth,
    teamFive,
    teamSix,
    teamSeven,
    teamEight,
    teamNine,
    teamTen,
    teamEleven,
    teamTwelve,
    teamThirteen,
    teamFourteen,
    teamFifteen,
    teamSixteen,
    teamSeventeen,
    teamEighteen,
    teamNinteen,
    teamTwenty,
} from './assets/images';

import {
  ADDRESS,
  EMAIL,
  EMAIL_HREF,
  PHONE,
  PHONE_HREF,
  WEBSITE_LOGO,
  WEBSITE_NAME,
} from './config';

import PublishingPartner from './components/PublishingPartner';
import PortfolioSlider from './components/PortfolioSlider';
import ProvideSlider from './components/ProvideSlider';
import BookSlider from './components/BookSlider';
import ReviewSlider from './components/ReviewSlider';
import FlushExample from './components/FlushExample';
import TabsComponent from './components/TabsComponent'; 

function App() {
  
  const handleClick = () => {
    // Initialize the LiveChat script on button click
    window.__lc = window.__lc || {};
    window.__lc.license = 18319443;
    window.__lc.integration_name = "manual_onboarding";
    window.__lc.product_name = "livechat";

    (function(n, t, c) {
      function i(n) {
        return e._h ? e._h.apply(null, n) : e._q.push(n)
      }
      var e = {
        _q: [],
        _h: null,
        _v: "2.0",
        on: function() {
          i(["on", c.call(arguments)])
        },
        once: function() {
          i(["once", c.call(arguments)])
        },
        off: function() {
          i(["off", c.call(arguments)])
        },
        get: function() {
          if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
          return i(["get", c.call(arguments)])
        },
        call: function() {
          i(["call", c.call(arguments)])
        },
        init: function() {
          var n = t.createElement("script");
          n.async = !0;
          n.type = "text/javascript";
          n.src = "https://cdn.livechatinc.com/tracking.js";
          t.head.appendChild(n)
        }
      };
      !n.__lc.asyncInit && e.init();
      n.LiveChatWidget = n.LiveChatWidget || e;
    }(window, document, [].slice));
  };

  useEffect(() => {
    // Function to toggle the LiveChat widget
    const toggleChat = () => {
      if (window.LiveChatWidget) {
        window.LiveChatWidget.call('maximize'); // Open the chat window
      }
    };

    // Select the button with class 'chat'
    const chatButtons = document.querySelectorAll('.chat');

    // Attach event listeners to each button
    chatButtons.forEach((button) => {
      if (button) {
        button.addEventListener('click', toggleChat);
      }
    });

    // Cleanup: Remove event listeners when the component unmounts
    return () => {
      chatButtons.forEach((button) => {
        if (button) {
          button.removeEventListener('click', toggleChat);
        }
      });
    };
  }, []);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: '',
      phone: '',
  });

  const onSubmitFunc = async (e) => {
  e.preventDefault();

  console.log('formData', formData);
  if (formData.phone.length < 9) {
    setErrors(true);
  } else {
    const currentUrl = window.location.href;
    const protocol = window.location.protocol; // "https:"
    const hostname = window.location.hostname; // "creativeghostwriters.org"

    // Construct the base URL
    const baseUrl = `${protocol}//${hostname}`;
    const queryStringFormData = new URLSearchParams(formData).toString();

    let finalReq = {
      ...formData,
      source: `${currentUrl}${queryStringFormData}`,
      domain: baseUrl,
      lead_url: currentUrl,
      url: `${currentUrl}${queryStringFormData}`,
    };
    try {
      setLoading(true);
      const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=787444';
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      const formDataString = new URLSearchParams(finalReq).toString();

      const response = await axios.post(apiUrl, formDataString, config);
      if (response.status == 200) {
        // toast.success("Thank you for filling out the information");
        // console.log(response.data.data.id);
        const queryString = new URLSearchParams(
          response.data.data
        ).toString();
        console.log('queryString', queryString);
        setLoading(false);
        setErrors(false);
        setFormData({
          name: '',
          email: '',
          message: '',
          phone: '',
        });
        // naviagte(`/thankyou?${queryString}`)
        window.location.href = `https://amazonkindlepublisher.co/thankyou?${queryString}`;
      }
      console.log('responseresponseresponse', response);
    } catch (error) {
      console.error('Error:', error);
    }
  }
  };
  return (
    <div className="App">
        <header class="lpHeader">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-5 col-sm-3"><a class="logo" href="javascript:;"><img src={logo}
                                alt="logo header" class="img-fluid"/></a></div>
                    <div class="col-7 col-sm-9">
                        <ul class="btnOpt">
                            <li><a href={PHONE_HREF} class="themeBtn themeBtn2"><i
                                        class="fas fa-phone-alt"></i>
                                    Call Now</a></li>
                            <li><a href={EMAIL_HREF} class="themeBtn themeBtn2"><i
                                        class="fas fa-envelope"></i> Contact Us</a></li>
                            <li><a href="javascript:;" class="themeBtn themeBtn2 chat" onClick={handleClick}><i class="fas fa-headphones"></i>
                                    Live
                                    Chat</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>

        <section class="mainBanner lpBanner publishLpOne"
            >
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-xl-6 col-lg-7 col-md-12">
                        <div class="bannerContnt">
                            <h6>Be The Next Best-Selling Author!</h6>
                            <h1 class="h3 fw-bold mb-3 text-white" >Get your book
                                published on 40+ Book Publishing Platforms</h1>
                            <ul class="listStyled mb-3">
                                <li>
                                    <i class="fa fa-check-square"></i>
                                    <p><strong>Keep 100% Royalty </strong> and Ownership of Book</p>
                                </li>
                                <li>
                                    <i class="fa fa-check-square"></i>
                                    <p>Simple and Smooth Process – <strong>Enjoy 50% Discount </strong></p>
                                </li>
                                <li>
                                    <i class="fa fa-check-square"></i>
                                    <p>Publish on amazon and<strong> 40+ renowned platforms </strong> like Amazon Kindle,
                                        Kobo, Barnes & Noble and many others.</p>
                                </li>

                                <li>
                                    <i class="fa fa-check-square"></i>
                                    <p><strong>Expedite your journey</strong> to the world's largest online marketplace.</p>
                                </li>
                            </ul>
                            <div class="btnSec mt-4 px-5 px-lg-0">
                                <a href="javascript:;" class="themeBtn themeBtn2 chat" onClick={handleClick}>Live Chat</a>
                            </div>

                            <div class="brandimages d-flex align-items-center gap-2 mt-3 mt-md-5">
                                <img loading="lazy" src={brandsFirst} alt="image" width="130" height="55"
                                    class=" " />
                                <img loading="lazy" src={brandsSecond} alt="image" width="130" height="55"
                                    class=" " />
                                <img loading="lazy" src={brandsThird} alt="image" width="130" height="55"
                                    class=" " />
                                <img loading="lazy" src={brandsFourth} alt="image" width="130" height="55"
                                    class=" " />
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-5">
                        <div class="contactBoxs leadLp">
                            <img loading="lazy" src={schtech} class="schtech" alt="schtech"/>
                            <div class="signupForm" id="banner-form">
                                <h4 class=" text-uppercase">Sign up now and lock <span class=" text-uppercase">50%
                                        Discount</span></h4>

                                <form class="gap-1 form_submission" method="POST" onSubmit={onSubmitFunc}>
                                    <input type="text" name="name" required class="form-control name"
                                        placeholder="Your Name" onChange={(e) =>setFormData({ ...formData, name: e.target.value })}/>
                                    <input type="email" name="email" required class="form-control email"
                                        placeholder="Your Email" onChange={(e) =>setFormData({ ...formData, email: e.target.value,})}/>
                                    <div class="phone-container">
                                        <input type="number" class="phone1" required name="phone"
                                            placeholder="Enter Your Phone" onChange={(e) =>setFormData({...formData,phone: e.target.value,})}/>
                                    </div>
                                    <div>
                                        <select name="data[service]" class="lpService virtual-select banner-select"
                                            multiple="" data-search="false" data-placeholder="Services I Need">
                                            <option value="Ghostwriting">Ghostwriting</option>
                                            <option value="Book Editing">Book Editing</option>
                                            <option value="Book Publishing">Book Publishing</option>
                                            <option value="Book Marketing">Book Marketing</option>
                                        </select>
                                    </div>
                                    {loading == true ? (
                                        <div class="loader">
                                            <img alt="loader" src={ajaxLoader}/>
                                        </div>
                                    ) : (
                                      <button type="submit" value="Submit"  class="themeBtn themeBtn-Black lp1formsubmit">Get
                                        Started</button>
                                    )}
                                    
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="logoSec">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h3>Publishing Partners</h3>
                        <div class="logoSlider">
                          <PublishingPartner />
                            {/* <div>
                                <img loading="lazy" src={logoFirst} alt="logo images" width="168"
                                    height="98" />
                            </div>
                            <div>
                                <img loading="lazy" src={logoSecond} alt="logo images" width="168"
                                    height="98" />
                            </div>
                            <div>
                                <img loading="lazy" src={logoThird} alt="logo images" width="168"
                                    height="98" />
                            </div>
                            <div>
                                <img loading="lazy" src={logoFourth} alt="logo images" width="168"
                                    height="98" />
                            </div>
                            <div>
                                <img loading="lazy" src={logoFive} alt="logo images" width="168"
                                    height="98" />
                            </div>
                            <div>
                                <img loading="lazy" src={logoSix} alt="logo images" width="168"
                                    height="98" />
                            </div>
                            <div>
                                <img loading="lazy" src={logoSeven} alt="logo images" width="168"
                                    height="98" />
                            </div>
                            <div>
                                <img loading="lazy" src={logoEight} alt="logo images" width="168"
                                    height="98" />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="portfolioSec">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h4>Our Distinctive Portfolio</h4>
                        <p>Explore our curated collection of Premier Publications.</p>
                    </div>
                    <div class="col-lg-12">
                        <div class="portfolioSlider">
                          <PortfolioSlider />
                        </div>
                    </div>
                    <div class="col-lg-12 text-center mt-5">
                        <h3>Amplify Your Presence through Powerful Words!</h3>
                    </div>
                </div>
            </div>
        </section>

        <section class="provideSec">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-6 text-center">
                        <h4>What We Provide</h4>
                        <h6>Transform Your Manuscript to a Marvel</h6>
                        <p>Explore Our Most Demanded Solutions!</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="provideSlider">
                          <ProvideSlider />
                            {/* <div>
                                <div class="provideBox">
                                    <img loading="lazy" src={iconsSeventySeventh} alt="images" width="60"
                                        height="60"/>
                                    <h3>Copyright Registration</h3>
                                    <h6>Enter the exclusive legal realm to give life to your literary masterpiece.</h6>

                                    <p>Ensure the security of your creative work through our seamless copyright registration
                                        services. This process guarantees irrefutable and legally sound ownership.</p>
                                    <a href="sequence/step1" class="themeBtn themeBtn2">Get 50% Off</a>
                                </div>
                            </div>
                            <div>
                                <div class="provideBox">
                                    <img loading="lazy" src={iconsSeventyTwo} alt="images" width="60"
                                        height="60"/>
                                    <h3>Ghostwriting</h3>
                                    <h6>Unleash the potential of your narrative with our team of expert ghostwriters. </h6>
                                    <p>Elevate your story with the expertise of top-rated scribes—partner with us for
                                        affordable packages and comprehensive services tailored to each author's unique
                                        needs.</p>
                                    <a href="sequence/step1" class="themeBtn themeBtn2">Get 50% Off</a>
                                </div>
                            </div>
                            <div>
                                <div class="provideBox">
                                    <img loading="lazy" src={iconsSeventyThree} alt="images" width="60"
                                        height="60"/>
                                    <h3>Editing & Proofreading</h3>
                                    <h6>Refine Your Book for 100% Authenticity</h6>
                                    <p>Our thorough process guarantees accuracy and excellence in the shortest time
                                        possible.
                                        Rigorous fact-checking ensures the utmost perfection in your final manuscript.
                                    </p>
                                    <a href="sequence/step1" class="themeBtn themeBtn2">Get 50% Off</a>
                                </div>
                            </div>
                            <div>
                                <div class="provideBox">
                                    <img loading="lazy" src={iconsSeventyFourth} alt="images" width="60"
                                        height="60"/>
                                    <h3>Book Publishing</h3>
                                    <h6>Introduce your words to the world through our book publication service. </h6>
                                    <p>Our responsibility is to secure acceptance for your manuscript with publishing agents
                                        and houses. We provide practical strategies to increase the likelihood of your
                                        manuscript acceptance by the industry.</p>
                                    <a href="sequence/step1" class="themeBtn themeBtn2">Get 50% Off</a>
                                </div>
                            </div>

                            <div>
                                <div class="provideBox">
                                    <img loading="lazy" src={iconsSeventyFifth} alt="images" width="60"
                                        height="60"/>
                                    <h3>Book Marketing</h3>
                                    <h6>Transform your books into bestselling publications.</h6>
                                    <p>Whether you need book promotion, author publicity, or a comprehensive marketing plan,
                                        we've got you covered. Our specialists have dedicated years to decoding algorithms,
                                        unveiling the secrets that drive the success of holistic marketing campaigns.</p>
                                    <a href="sequence/step1" class="themeBtn themeBtn2">Get 50% Off</a>
                                </div>
                            </div>
                            <div>
                                <div class="provideBox">
                                    <img loading="lazy" src={iconsSeventySixth} alt="images" width="60"
                                        height="60"/>
                                    <h3>Book Formatting</h3>
                                    <h6>Ensure your books are not only well-written but perfect.</h6>
                                    <p>We guarantee that your manuscript adheres to global formatting standards. Every
                                        detail, from font styles to margins, is meticulously handled to meet industry
                                        expectations.</p>
                                    <a href="sequence/step1" class="themeBtn themeBtn2">Get 50% Off</a>
                                </div>
                            </div>
                            <div>
                                <div class="provideBox">
                                    <img loading="lazy" src={iconsSeventyOne} alt="images" width="60"
                                        height="60"/>
                                    <h3>Cover Design</h3>
                                    <h6>Connect with us for a cover that captivates and engages viewers.</h6>
                                    <p>Our collaborative team of designers and writers works to create enticing book covers
                                        that resonate with your target audience and align with market demands.
                                    </p>
                                    <a href="sequence/step1" class="themeBtn themeBtn2">Get 50% Off</a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    {/* <div class="col-lg-12">
                        <!-- <div class="btnSec ">
                            <a href="#" class="themeBtn roleback">Get Started</a>
                            <a href="javascript:;" class="themeBtn themeBtn-Black">Live
                                Chat</a>
                        </div> -->
                    </div> */}
                </div>
            </div>
        </section>

        <section class="exceptionalSec">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <h4>What Makes Us Exceptional?</h4>
                    </div>
                    <ul>
                        <li><img loading="lazy" src={iconsFourth} alt="image" width="40" height="40"/>
                            <p><span>Ensuring 100% Client Satisfaction</span>Unwavering commitment to quality, ensuring 100%
                                client satisfaction</p>
                        </li>
                        <li><img loading="lazy" src={iconsFive} alt="image" width="40" height="40"/>
                            <p><span>Mastering Every Aspect</span>Our decade-long experience in the publishing industry
                                empowers us to excel in every aspect of
                                book publication</p>
                        </li>
                        <li><img loading="lazy" src={iconsSix} alt="image" width="40" height="40"/>
                            <p>
                                <span>Retain Your Rights and Royalties</span>You own full ownership rights to your book and
                                keep all the royalties
                            </p>
                        </li>
                        <li><img loading="lazy" src={iconsSeven} alt="image" width="40" height="40"/>
                            <p><span>Global Trust</span></p>
                            <p>Trusted by bestselling authors and accomplished entrepreneurs all over the world</p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="abtSec">
            <img loading="lazy" src={bgFirst} class="abtArrow" alt="images"/>
            <div class="container">
                <div class="row align-items-center justify-content-between">
                        <div class="col-lg-6">
                          <h4>Where Imagination Takes Flight</h4>
                          <h6>Embark on an adventure with
                              Amazon Kindle Publisher, where your literary masterpiece is entrusted
                              to capable hands.
                          </h6>
                          <p>Beyond conventional publishing, we navigate you through self-publishing, forging connections with
                              literary agents. As seasoned publishers, our comprehensive services include copyright
                              protection, unique ISBN barcodes, LCC numbers, immersive eBook trailers, captivating audiobook
                              narration, and more.</p>
                          {/* <div class="btnSec">
                              <!-- <a href="#" class="themeBtn roleback">Get Started</a>
                              <a href="javascript:;" class="themeBtn themeBtn-Black">Live
                                  Chat</a>
                          </div> --> */}
                        </div>
                        <div class="col-lg-5">
                            <figure>
                                <img loading="lazy" src={mixFirst} alt="images" width="452" height="471"
                                    class=" w-100 h-100" />
                            </figure>
                        </div>
                  </div>
            </div>
        </section>

        <section class="publishCateSec portfolioSec">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="title text-center">
                            <h4>What Kind of Book Spectrum Do We Cover?</h4>
                            <p>From ghostwriting to editing to publishing, we oversee the complete spectrum of book
                                creation, covering an array of genres.</p>
                        </div>
                        <div class="bookSlider">
                          <BookSlider />
                            {/* <div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesFirst}/>
                                    </figure>
                                    <h3>autobiography</h3>
                                </div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesSecond}/>
                                    </figure>
                                    <h3>biography</h3>
                                </div>
                            </div>
                            <div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesThird}/>
                                    </figure>
                                    <h3>Business</h3>
                                </div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesFourth}/>
                                    </figure>
                                    <h3>Childrens Book</h3>
                                </div>
                            </div>
                            <div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesFive}/>

                                    </figure>
                                    <h3>Comedy</h3>
                                </div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesSix}/>
                                    </figure>
                                    <h3>Comics</h3>
                                </div>
                            </div>
                            <div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesSeven}/>
                                    </figure>
                                    <h3>Crime fiction</h3>
                                </div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesEight}/>
                                    </figure>
                                    <h3>Fiction books</h3>
                                </div>
                            </div>
                            <div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesNine}/>
                                    </figure>
                                    <h3>Hiphop</h3>
                                </div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesTen}/>
                                    </figure>
                                    <h3>memoir</h3>
                                </div>
                            </div>
                            <div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesEleven}/>
                                    </figure>
                                    <h3>Military fiction</h3>
                                </div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesTwelve}/>
                                    </figure>
                                    <h3>Narrative</h3>
                                </div>
                            </div>
                            <div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesThirteen}/>
                                    </figure>
                                    <h3>Novel</h3>
                                </div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesFourteen}/>
                                    </figure>
                                    <h3>Rhyme</h3>
                                </div>
                            </div>
                            <div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesFifteen}/>
                                    </figure>
                                    <h3>science fiction</h3>
                                </div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesSixteen}/>
                                    </figure>
                                    <h3>Screenplay</h3>
                                </div>
                            </div>
                            <div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesSeventeen}/>
                                    </figure>
                                    <h3>SEO content</h3>
                                </div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesEighteen}/>
                                    </figure>
                                    <h3>song writing</h3>
                                </div>
                            </div>
                            <div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesNinteen}/>
                                    </figure>
                                    <h3>speech</h3>
                                </div>
                                <div class="bookKindBox">
                                    <figure>
                                        <img loading="lazy" alt="images" width="343" height="190" 
                                            src={categoriesTwenty}/>
                                    </figure>
                                    <h3>wiki writing</h3>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="teamSec">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <div class="headingStyleThree mb-5" data-aos="fade-down" data-aos-delay="100"
                            data-aos-duration="300">
                            <h4>Our Core Team</h4>
                            <p>Meet our team of New York Times Bestselling Writers, Editors,
                                Marketers, designers and Publicists</p>
                        </div>
                    </div>
                    <div class="col-md-12 mb-5">
                        {/* <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="home-tab" data-bs-toggle="tab"
                                    data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane"
                                    aria-selected="true">Editing Team</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="profile-tab" data-bs-toggle="tab"
                                    data-bs-target="#profile-tab-pane" type="button" role="tab"
                                    aria-controls="profile-tab-pane" aria-selected="false">Marketing Team</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="contact-tab" data-bs-toggle="tab"
                                    data-bs-target="#contact-tab-pane" type="button" role="tab"
                                    aria-controls="contact-tab-pane" aria-selected="false">Publishing Team</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="disabled-tab" data-bs-toggle="tab"
                                    data-bs-target="#disabled-tab-pane" type="button" role="tab"
                                    aria-controls="disabled-tab-pane" aria-selected="false">Ghostwriting Team</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="disabled-tab-five" data-bs-toggle="tab"
                                    data-bs-target="#disabled-tab-pane-five" type="button" role="tab"
                                    aria-controls="disabled-tab-pane-five" aria-selected="false">Cover Design
                                    Team</button>
                            </li>
                        </ul>
                    </div>

                    <div class="tab-content" id="myTabContent"> */}
                        {/* <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
                            tabindex="0">
                            <EditingTab />
                        </div>
                        <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab"
                            tabindex="0">
                            <EditingTab />
                            <div class="row">
                                <div class="col-lg-3 col-md-6">
                                    <div class="teamBox">
                                        <figure>
                                            <img loading="lazy" alt="image" width="262" height="354" class="img-fluid"
                                                src={teamFive}/>
                                        </figure>
                                        <div class="overlay-text">
                                            <h4>Charles Bills</h4>
                                            <p>Marketing Maestro</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="teamBox">
                                        <figure>
                                            <img loading="lazy" alt="image" width="262" height="354" class="img-fluid"
                                                src={teamSix}/>
                                        </figure>
                                        <div class="overlay-text">
                                            <h4>Gerald Silverman</h4>
                                            <p>Marketing Manager</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="teamBox">
                                        <figure>
                                            <img loading="lazy" alt="image" width="262" height="354" class="img-fluid"
                                                src={teamSeven}/>
                                        </figure>
                                        <div class="overlay-text">
                                            <h4>Kevin Hand</h4>
                                            <p>Marketing Strategist</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="teamBox">
                                        <figure>
                                            <img loading="lazy" alt="image" width="262" height="354" class="img-fluid"
                                                src={teamEight}/>
                                        </figure>
                                        <div class="overlay-text">
                                            <h4>Patricia Browne</h4>
                                            <p>Marketing Director</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <TabsComponent />
                        {/* <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab"
                            tabindex="0">
                            <div class="row">
                                <div class="col-lg-3 col-md-6">
                                    <div class="teamBox">
                                        <figure>
                                            <img loading="lazy" alt="image" width="262" height="354" class="img-fluid"
                                                src={teamNine}/>
                                        </figure>
                                        <div class="overlay-text">
                                            <h4>Kenneth Erikson </h4>
                                            <p>Publication Consultant</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="teamBox">
                                        <figure>
                                            <img loading="lazy" alt="image" width="262" height="354" class="img-fluid"
                                                src={teamTen}/>
                                        </figure>
                                        <div class="overlay-text">
                                            <h4>Laureen Rowe</h4>
                                            <p>publicist</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="teamBox">
                                        <figure>
                                            <img loading="lazy" alt="image" width="262" height="354" class="img-fluid"
                                                src={teamEleven}/>
                                        </figure>
                                        <div class="overlay-text">
                                            <h4>Dolores Barrera</h4>
                                            <p>literary agent</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="teamBox">
                                        <figure>
                                            <img loading="lazy" alt="image" width="262" height="354" class="img-fluid"
                                                src={teamTwelve}/>
                                        </figure>
                                        <div class="overlay-text">
                                            <h4>Max Cooper </h4>
                                            <p>Publication Consultant</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab"
                            tabindex="0">
                            <div class="row">
                                <div class="col-lg-3 col-md-6">
                                    <div class="teamBox">
                                        <figure>
                                            <img loading="lazy" alt="image" width="262" height="354" class="img-fluid"
                                                src={teamThirteen}/>
                                        </figure>
                                        <div class="overlay-text">
                                            <h4>Bill Edwards</h4>
                                            <p>Ghostwriter</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="teamBox">
                                        <figure>
                                            <img loading="lazy" alt="image" width="262" height="354" class="img-fluid"
                                                src={teamFourteen}/>
                                        </figure>
                                        <div class="overlay-text">
                                            <h4>Carol Sandberg</h4>
                                            <p>Technical Ghostwriter</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="teamBox">
                                        <figure>
                                            <img loading="lazy" alt="image" width="262" height="354" class="img-fluid"
                                                src={teamFifteen}/>
                                        </figure>
                                        <div class="overlay-text">
                                            <h4>Morgan Leonard</h4>
                                            <p>Podcast Script Specialist</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="teamBox">
                                        <figure>
                                            <img loading="lazy" alt="image" width="262" height="354" class="img-fluid"
                                                src={teamSixteen}/>
                                        </figure>
                                        <div class="overlay-text">
                                            <h4>Beatrice Silvas</h4>
                                            <p>Chief Grant Proposal Ghostwriter</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="disabled-tab-pane-five" role="tabpanel"
                            aria-labelledby="disabled-tab" tabindex="0">

                            <div class="row">
                                <div class="col-lg-3 col-md-6">
                                    <div class="teamBox">
                                        <figure>
                                            <img loading="lazy" alt="image" width="262" height="354" class="img-fluid"
                                                src={teamSeventeen}/>
                                        </figure>
                                        <div class="overlay-text">
                                            <h4>Mary Scott</h4>
                                            <p>Graphic Artist</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="teamBox">
                                        <figure>
                                            <img loading="lazy" alt="image" width="262" height="354" class="img-fluid"
                                                src={teamEighteen}/>
                                        </figure>
                                        <div class="overlay-text">
                                            <h4>Edward Thompson</h4>
                                            <p>Color Palette Specialist</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="teamBox">
                                        <figure>
                                            <img loading="lazy" alt="image" width="262" height="354" class="img-fluid"
                                                src={teamNinteen}/>
                                        </figure>
                                        <div class="overlay-text">
                                            <h4>Kelly Spence</h4>
                                            <p>Visual Storyteller</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="teamBox">
                                        <figure>
                                            <img loading="lazy" alt="image" width="262" height="354" class="img-fluid"
                                                src={teamTwenty}/>
                                        </figure>
                                        <div class="overlay-text">
                                            <h4>Chris Newton</h4>
                                            <p>Design Director</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>

        <section class="contactSec contactBoxs lpContactSec">
            <div class="container">
                <div class="row justify-content-between align-items-center">
                    <div class="col-lg-12">
                        <div class="logoForm">
                            <div class="logoRowOne">
                                <div class="logo logo5">
                                    <img loading="lazy" src={platformFourteen} alt="images" width="116"
                                        height="73" />
                                </div>
                                <div class="logo logo2">
                                    <img loading="lazy" src={platformTwelve} alt="images" width="116"
                                        height="73" />
                                </div>
                            </div>
                            <div class="logoRowTwo">
                                <div class="logo logo6">
                                    <img loading="lazy" src={platformThirteen} alt="images" width="116"
                                        height="73" />
                                </div>
                                <div class="logo logo1">
                                    <img loading="lazy" src={platformNine} alt="images" width="116"
                                        height="73" />
                                </div>
                            </div>
                            <div class="logoRowThree">
                                <div class="logo logo4">
                                    <img loading="lazy" src={platformTen} alt="images" width="116"
                                        height="73" />
                                </div>
                                <div class="logo logo3">
                                    <img loading="lazy" src={platformEleven} alt="images" width="116"
                                        height="73" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="reviewSec">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8 text-center">
                        <h4>Testimonials</h4>
                        <p>At Amazon Kindle Publisher, we stand out as the premier book publishers, dedicated to crafting
                            captivating stories and bringing your words to life. Publish a book on Amazon and discover why
                            we're the best in the business for publishing excellence.
                        </p>
                    </div>
                    <div class="col-lg-12">
                        <div class="reviewSlider">
                            <ReviewSlider />
                            {/* <div>
                                <div class="reviewBox">
                                    <div class="reviewHead">
                                        <img loading="lazy" src={mixUserFirst} alt="image" width="75"
                                            height="75"/>
                                        <h3>Ann R. Hensley</h3>
                                    </div>
                                    <p>
                                        As someone whose mind is always buzzing with thoughts, putting them down on paper
                                        has
                                        been a bit tricky for me. However, the team's support has been beyond amazing. Their
                                        professionalism has shone through at every stage, and I genuinely feel I can trust
                                        them
                                        for my future book writing endeavors.
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div class="reviewBox">
                                    <div class="reviewHead">
                                        <img loading="lazy" src={mixUserSecond} alt="image" width="75"
                                            height="75"/>
                                        <h3>Emily Delgado</h3>
                                    </div>
                                    <p>The Amazon Kindle Publisher truly delivered for me when it came to crafting my
                                        first
                                        book.
                                        They quickly made the changes I requested and kept me in the loop at every step.
                                        Choosing them for my debut book was a great decision, and I'm genuinely thrilled
                                        with
                                        the results!
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div class="reviewBox">
                                    <div class="reviewHead">
                                        <img loading="lazy" src={mixUserThird} alt="image" width="75"
                                            height="75"/>
                                        <h3>Margaret Brown</h3>
                                    </div>
                                    <p>I've been collaborating with APW for a year and have already published one successful
                                        book with them. Even though there's more ground to cover with additional projects in
                                        the
                                        pipeline, I have full confidence in APW.</p>
                                </div>
                            </div>

                            <div>
                                <div class="reviewBox">
                                    <div class="reviewHead">
                                        <img loading="lazy" src={mixUserFourth} alt="image" width="75"
                                            height="75"/>
                                        <h3>Thomas Bower</h3>
                                    </div>
                                    <p>
                                        Amazon Kindle Publisher is the go-to place for anyone looking to kickstart their
                                        book
                                        publishing journey on various platforms, including KDP. They assisted me in getting
                                        my
                                        book out there for a reasonable fee. I highly recommend them.
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div class="reviewBox">
                                    <div class="reviewHead">
                                        <img loading="lazy" src={mixUserFive} alt="image" width="75"
                                            height="75"/>
                                        <h3>Wilma Ford</h3>
                                    </div>
                                    <p>I'm truly grateful for Sarah's understanding of my publishing needs and her efforts
                                        to
                                        elevate the quality of my book. Thank you for turning my dream of becoming a
                                        published
                                        author into a reality.
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div class="reviewBox">
                                    <div class="reviewHead">
                                        <img loading="lazy" src={mixUserSix} alt="image" width="75"
                                            height="75"/>
                                        <h3>Paul Giles</h3>
                                    </div>
                                    <p>I'm thrilled with Amazon Kindle Publisher and the fantastic job their team did
                                        with my
                                        book
                                        cover. They went above and beyond my expectations, creating an incredibly artistic
                                        and
                                        beautiful design. Everyone who has seen the cover is impressed!
                                    </p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="ctaSec">
            <div class="container">
                <div class="row align-items-center justify-content-between">
                    <div class="col-lg-7">
                        <h4>Kindle Your Book’s Triumph & Harness Our Publishing Prowess!</h4>
                    </div>
                    <div class="col-lg-3">
                        <img loading="lazy" src={mixFourth} alt="images" width="262" height="394"
                            />
                    </div>
                </div>
            </div>
        </section>

        <section class="faqSec">
            <div class="container">
                <div class="row align-items-center justify-content-between">
                    <div class="col-lg-12 text-center">
                        <div class="headingStyleThree" data-aos="fade-up" data-aos-delay="200" data-aos-duration="400">
                            <h4>Frequently Asked Questions</h4>
                        </div>
                    </div>
                    <div class="col-lg-5"><img loading="lazy" src={mixSecond} alt="image" width="452"
                            height="495" /></div>
                    <div class="col-lg-6">
                    <FlushExample />
                        {/* <div class="accordion accordionStyle" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        What is Self-Publishing?</button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Self-publishing is the process where an author independently publishes their
                                            book, taking on responsibilities such as editing, formatting, and marketing.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        What
                                        is Amazon publishing?</button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>Amazon publishing refers to the platform provided by Amazon where authors can
                                            publish
                                            and sell their books through Kindle Direct Publishing (KDP) or other Amazon
                                            imprints.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        What
                                        is an ISBN?</button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>An ISBN (International Standard Book Number) is a unique identifier for books,
                                            facilitating their identification and distribution in the publishing industry.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        How
                                        can you acquire the assistance of
                                        bookmarketeers?</button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>You can acquire the assistance of book marketers by hiring our professional
                                            marketing
                                            services that specialize in promoting books through various channels.</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>

        <footer class=" bg-dark py-5">
            <div class="container text-center">
                <a href="javascript:;" class=" user-select-none pe-none">
                    <img loading="lazy" src={logo} alt="image" width="250" height="120"
                         class=" mb-3 mx-auto"/>
                </a>
                <p class=" text-white">Unlock Your Author Dreams with Amazon Kindle Publisher!</p>

                <ul
                    class="d-inline-flex align-items-center justify-content-center gap-1 gap-md-3 bg-white p-3 rounded-4 flex-wrap my-3">
                    <li><a href={EMAIL_HREF} class="d-flex align-items-baseline gap-2 fw-semibold"
                            ><i class="far fa-envelope"></i>
                            {EMAIL}</a></li>
                    <li><a href={PHONE_HREF} class="d-flex align-items-baseline gap-2 fw-semibold"
                            ><i class="fas fa-phone-alt"></i>
                            {PHONE}</a></li>
                    <li><a href="javascript:;" class="d-flex align-items-baseline gap-2 pe-none fw-semibold"
                            ><i class="fas fa-map-marker-alt"></i>{ADDRESS}</a>

                    </li>
                </ul>
                <div class="row copyRigh py-2 align-items-center">
                    <div class="col-12 text-center">
                        <p class=" m-0 text-white">©2024 Amazon Kindle Publisher All Rights
                            Reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
  );
}

export default App;
