// DesignTab.js
import React from 'react';
import {
    teamSeventeen,
    teamEighteen,
    teamNinteen,
    teamTwenty,
} from '../assets/images'; // Import images

const DesignTab = () => {
  return (
    <div className="row">
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Mary Scott"
              width="262"
              height="354"
              className="img-fluid"
              src={teamSeventeen}
            />
          </figure>
          <div className="overlay-text">
            <h4>Mary Scott</h4>
            <p>Graphic Artist</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Edward Thompson"
              width="262"
              height="354"
              className="img-fluid"
              src={teamEighteen}
            />
          </figure>
          <div className="overlay-text">
            <h4>Edward Thompson</h4>
            <p>Color Palette Specialist</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Kelly Spence"
              width="262"
              height="354"
              className="img-fluid"
              src={teamNinteen}
            />
          </figure>
          <div className="overlay-text">
            <h4>Kelly Spence</h4>
            <p>Visual Storyteller</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Chris Newton"
              width="262"
              height="354"
              className="img-fluid"
              src={teamTwenty}
            />
          </figure>
          <div className="overlay-text">
            <h4>Chris Newton</h4>
            <p>Design Director</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignTab;
