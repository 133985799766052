import React from "react";
import Slider from "react-slick";

import {
      logoFirst,
      logoSecond,
      logoThird,
      logoFourth,
      logoFive,
      logoSix,
      logoSeven,
      logoEight,
  } from '../assets/images';


export default function PublishingPartner() {
  var settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
  };
  return (
    <Slider {...settings}>
        <div>
            <img loading="lazy" src={logoFirst} alt="logo images" width="168"
                height="98" />
        </div>
        <div>
            <img loading="lazy" src={logoSecond} alt="logo images" width="168"
                height="98" />
        </div>
        <div>
            <img loading="lazy" src={logoThird} alt="logo images" width="168"
                height="98" />
        </div>
        <div>
            <img loading="lazy" src={logoFourth} alt="logo images" width="168"
                height="98" />
        </div>
        <div>
            <img loading="lazy" src={logoFive} alt="logo images" width="168"
                height="98" />
        </div>
        <div>
            <img loading="lazy" src={logoSix} alt="logo images" width="168"
                height="98" />
        </div>
        <div>
            <img loading="lazy" src={logoSeven} alt="logo images" width="168"
                height="98" />
        </div>
        <div>
            <img loading="lazy" src={logoEight} alt="logo images" width="168"
                height="98" />
        </div>
    </Slider>
  );
}