// MarketingTab.js
import React from 'react';
import {
    teamFive,
    teamSix,
    teamSeven,
    teamEight,
} from '../assets/images'; // Import images

const MarketingTab = () => {
  return (
    <div className="row">
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Charles Bills"
              width="262"
              height="354"
              className="img-fluid"
              src={teamFive}
            />
          </figure>
          <div className="overlay-text">
            <h4>Charles Bills</h4>
            <p>Marketing Maestro</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Gerald Silverman"
              width="262"
              height="354"
              className="img-fluid"
              src={teamSix}
            />
          </figure>
          <div className="overlay-text">
            <h4>Gerald Silverman</h4>
            <p>Marketing Manager</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Kevin Hand"
              width="262"
              height="354"
              className="img-fluid"
              src={teamSeven}
            />
          </figure>
          <div className="overlay-text">
            <h4>Kevin Hand</h4>
            <p>Marketing Strategist</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Patricia Browne"
              width="262"
              height="354"
              className="img-fluid"
              src={teamEight}
            />
          </figure>
          <div className="overlay-text">
            <h4>Patricia Browne</h4>
            <p>Marketing Director</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingTab;
