// TabsComponent.js
import React, { useState } from 'react';
import EditingTab from './EditingTab';
import MarketingTab from './MarketingTab';
import ContactTab from './ContactTab';
import DisabledTab from './DisabledTab';
import DesignTab from './DesignTab';

const TabsComponent = () => {
  const [activeTab, setActiveTab] = useState('editing');

  return (
    <div>
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'editing' ? 'active' : ''}`}
            onClick={() => setActiveTab('editing')}
            type="button"
            role="tab"
            aria-selected={activeTab === 'editing'}
          >
            Editing Team
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'marketing' ? 'active' : ''}`}
            onClick={() => setActiveTab('marketing')}
            type="button"
            role="tab"
            aria-selected={activeTab === 'marketing'}
          >
            Marketing Team
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'contact' ? 'active' : ''}`}
            onClick={() => setActiveTab('contact')}
            type="button"
            role="tab"
            aria-selected={activeTab === 'contact'}
          >
            Contact Team
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'disabled' ? 'active' : ''}`}
            onClick={() => setActiveTab('disabled')}
            type="button"
            role="tab"
            aria-selected={activeTab === 'disabled'}
          >
            Disabled Team
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'design' ? 'active' : ''}`}
            onClick={() => setActiveTab('design')}
            type="button"
            role="tab"
            aria-selected={activeTab === 'design'}
          >
            Cover Design Team
          </button>
        </li>
      </ul>

      <div className="tab-content">
        <div
          className={`tab-pane fade ${activeTab === 'editing' ? 'show active' : ''}`}
          role="tabpanel"
        >
          <EditingTab />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'marketing' ? 'show active' : ''}`}
          role="tabpanel"
        >
          <MarketingTab />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'contact' ? 'show active' : ''}`}
          role="tabpanel"
        >
          <ContactTab />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'disabled' ? 'show active' : ''}`}
          role="tabpanel"
        >
          <DisabledTab />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'design' ? 'show active' : ''}`}
          role="tabpanel"
        >
          <DesignTab />
        </div>
      </div>
    </div>
  );
};

export default TabsComponent;
