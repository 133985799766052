import Accordion from 'react-bootstrap/Accordion';

export default function FlushExample() {
  return (
    <Accordion defaultActiveKey="0" flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>What is Self-Publishing?</Accordion.Header>
        <Accordion.Body>
        Self-publishing is the process where an author independently publishes their book, taking on responsibilities such as editing, formatting, and marketing.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What is Amazon publishing?</Accordion.Header>
        <Accordion.Body>
        Amazon publishing refers to the platform provided by Amazon where authors can publish and sell their books through Kindle Direct Publishing (KDP) or other Amazon imprints.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>What is an ISBN?</Accordion.Header>
        <Accordion.Body>
        An ISBN (International Standard Book Number) is a unique identifier for books, facilitating their identification and distribution in the publishing industry.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>How can you acquire the assistance of bookmarketeers?</Accordion.Header>
        <Accordion.Body>
        You can acquire the assistance of book marketers by hiring our professional marketing services that specialize in promoting books through various channels.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}