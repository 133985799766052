import React from "react";
import Slider from "react-slick";

import {
    logo,
      ajaxLoader,
      fav,
      schtech,
      lpBanner,
      bgFirst,
      bgSecond,
      bgThird,
      bgFourth,
      bgFive,
      booksFirst,
      booksSecond,
      booksThird,
      booksFourth,
      booksFive,
      booksSix,
      booksSeven,
      booksEight,
      booksNine,
      booksTen,
      booksEleven,
      booksTwelve,
      booksThirteen,
      booksFourteen,
      booksBg,
      brandsFirst,
      brandsSecond,
      brandsThird,
      brandsFourth,
      categoriesFirst,
      categoriesSecond,
      categoriesThird,
      categoriesFourth,
      categoriesFive,
      categoriesSix,
      categoriesSeven,
      categoriesEight,
      categoriesNine,
      categoriesTen,
      categoriesEleven,
      categoriesTwelve,
      categoriesThirteen,
      categoriesFourteen,
      categoriesFifteen,
      categoriesSixteen,
      categoriesSeventeen,
      categoriesEighteen,
      categoriesNinteen,
      categoriesTwenty,
      iconsFourth,
      iconsFive,
      iconsSix,
      iconsSeven,
      iconsSeventyOne,
      iconsSeventyTwo,
      iconsSeventyThree,
      iconsSeventyFourth,
      iconsSeventyFifth,
      iconsSeventySixth,
      iconsSeventySeventh,
      logoFirst,
      logoSecond,
      logoThird,
      logoFourth,
      logoFive,
      logoSix,
      logoSeven,
      logoEight,
      mixFirst,
      mixSecond,
      mixFourth,
      mixUserFirst,
      mixUserSecond,
      mixUserThird,
      mixUserFourth,
      mixUserFive,
      mixUserSix,
      platformNine,
      platformTen,
      platformEleven,
      platformTwelve,
      platformThirteen,
      platformFourteen,
      teamFirst,
      teamSecond,
      teamThird,
      teamFourth,
      teamFive,
      teamSix,
      teamSeven,
      teamEight,
      teamNine,
      teamTen,
      teamEleven,
      teamTwelve,
      teamThirteen,
      teamFourteen,
      teamFifteen,
      teamSixteen,
      teamSeventeen,
      teamEighteen,
      teamNinteen,
      teamTwenty,
  } from '../assets/images';


export default function BookSlider() {
  var settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
  };
  return (
    <Slider {...settings}>
        <div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesFirst}/>
                </figure>
                <h3>autobiography</h3>
            </div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesSecond}/>
                </figure>
                <h3>biography</h3>
            </div>
        </div>
        <div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesThird}/>
                </figure>
                <h3>Business</h3>
            </div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesFourth}/>
                </figure>
                <h3>Childrens Book</h3>
            </div>
        </div>
        <div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesFive}/>

                </figure>
                <h3>Comedy</h3>
            </div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesSix}/>
                </figure>
                <h3>Comics</h3>
            </div>
        </div>
        <div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesSeven}/>
                </figure>
                <h3>Crime fiction</h3>
            </div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesEight}/>
                </figure>
                <h3>Fiction books</h3>
            </div>
        </div>
        <div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesNine}/>
                </figure>
                <h3>Hiphop</h3>
            </div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesTen}/>
                </figure>
                <h3>memoir</h3>
            </div>
        </div>
        <div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesEleven}/>
                </figure>
                <h3>Military fiction</h3>
            </div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesTwelve}/>
                </figure>
                <h3>Narrative</h3>
            </div>
        </div>
        <div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesThirteen}/>
                </figure>
                <h3>Novel</h3>
            </div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesFourteen}/>
                </figure>
                <h3>Rhyme</h3>
            </div>
        </div>
        <div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesFifteen}/>
                </figure>
                <h3>science fiction</h3>
            </div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesSixteen}/>
                </figure>
                <h3>Screenplay</h3>
            </div>
        </div>
        <div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesSeventeen}/>
                </figure>
                <h3>SEO content</h3>
            </div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesEighteen}/>
                </figure>
                <h3>song writing</h3>
            </div>
        </div>
        <div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesNinteen}/>
                </figure>
                <h3>speech</h3>
            </div>
            <div class="bookKindBox">
                <figure>
                    <img loading="lazy" alt="images" width="343" height="190" 
                        src={categoriesTwenty}/>
                </figure>
                <h3>wiki writing</h3>
            </div>
        </div>
    </Slider>
  );
}