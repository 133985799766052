// DisabledTab.js
import React from 'react';
import {
    teamThirteen,
    teamFourteen,
    teamFifteen,
    teamSixteen,
} from '../assets/images'; // Import images

const DisabledTab = () => {
  return (
    <div className="row">
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Bill Edwards"
              width="262"
              height="354"
              className="img-fluid"
              src={teamThirteen}
            />
          </figure>
          <div className="overlay-text">
            <h4>Bill Edwards</h4>
            <p>Ghostwriter</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Carol Sandberg"
              width="262"
              height="354"
              className="img-fluid"
              src={teamFourteen}
            />
          </figure>
          <div className="overlay-text">
            <h4>Carol Sandberg</h4>
            <p>Technical Ghostwriter</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Morgan Leonard"
              width="262"
              height="354"
              className="img-fluid"
              src={teamFifteen}
            />
          </figure>
          <div className="overlay-text">
            <h4>Morgan Leonard</h4>
            <p>Podcast Script Specialist</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Beatrice Silvas"
              width="262"
              height="354"
              className="img-fluid"
              src={teamSixteen}
            />
          </figure>
          <div className="overlay-text">
            <h4>Beatrice Silvas</h4>
            <p>Chief Grant Proposal Ghostwriter</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisabledTab;
