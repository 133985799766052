// ContactTab.js
import React from 'react';
import {
    teamNine,
    teamTen,
    teamEleven,
    teamTwelve,
} from '../assets/images'; // Import images

const ContactTab = () => {
  return (
    <div className="row">
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Kenneth Erikson"
              width="262"
              height="354"
              className="img-fluid"
              src={teamNine}
            />
          </figure>
          <div className="overlay-text">
            <h4>Kenneth Erikson</h4>
            <p>Publication Consultant</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Laureen Rowe"
              width="262"
              height="354"
              className="img-fluid"
              src={teamTen}
            />
          </figure>
          <div className="overlay-text">
            <h4>Laureen Rowe</h4>
            <p>Publicist</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Dolores Barrera"
              width="262"
              height="354"
              className="img-fluid"
              src={teamEleven}
            />
          </figure>
          <div className="overlay-text">
            <h4>Dolores Barrera</h4>
            <p>Literary Agent</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Max Cooper"
              width="262"
              height="354"
              className="img-fluid"
              src={teamTwelve}
            />
          </figure>
          <div className="overlay-text">
            <h4>Max Cooper</h4>
            <p>Publication Consultant</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactTab;
