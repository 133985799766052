import React from "react";
import Slider from "react-slick";

import {
    logo,
      ajaxLoader,
      fav,
      schtech,
      lpBanner,
      bgFirst,
      bgSecond,
      bgThird,
      bgFourth,
      bgFive,
      booksFirst,
      booksSecond,
      booksThird,
      booksFourth,
      booksFive,
      booksSix,
      booksSeven,
      booksEight,
      booksNine,
      booksTen,
      booksEleven,
      booksTwelve,
      booksThirteen,
      booksFourteen,
      booksBg,
      brandsFirst,
      brandsSecond,
      brandsThird,
      brandsFourth,
      categoriesFirst,
      categoriesSecond,
      categoriesThird,
      categoriesFourth,
      categoriesFive,
      categoriesSix,
      categoriesSeven,
      categoriesEight,
      categoriesNine,
      categoriesTen,
      categoriesEleven,
      categoriesTwelve,
      categoriesThirteen,
      categoriesFourteen,
      categoriesFifteen,
      categoriesSixteen,
      categoriesSeventeen,
      categoriesEighteen,
      categoriesNinteen,
      categoriesTwenty,
      iconsFourth,
      iconsFive,
      iconsSix,
      iconsSeven,
      iconsSeventyOne,
      iconsSeventyTwo,
      iconsSeventyThree,
      iconsSeventyFourth,
      iconsSeventyFifth,
      iconsSeventySixth,
      iconsSeventySeventh,
      logoFirst,
      logoSecond,
      logoThird,
      logoFourth,
      logoFive,
      logoSix,
      logoSeven,
      logoEight,
      mixFirst,
      mixSecond,
      mixFourth,
      mixUserFirst,
      mixUserSecond,
      mixUserThird,
      mixUserFourth,
      mixUserFive,
      mixUserSix,
      platformNine,
      platformTen,
      platformEleven,
      platformTwelve,
      platformThirteen,
      platformFourteen,
      teamFirst,
      teamSecond,
      teamThird,
      teamFourth,
      teamFive,
      teamSix,
      teamSeven,
      teamEight,
      teamNine,
      teamTen,
      teamEleven,
      teamTwelve,
      teamThirteen,
      teamFourteen,
      teamFifteen,
      teamSixteen,
      teamSeventeen,
      teamEighteen,
      teamNinteen,
      teamTwenty,
  } from '../assets/images';


export default function PortfolioSlider() {
  var settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
  };
  return (
    <Slider {...settings}>
        <div>
            <img loading="lazy" src={booksFirst} alt="images" width="242" height="375"
                />
        </div>
        <div>
            <img loading="lazy" src={booksSecond} alt="images" width="242" height="375"
                />
        </div>
        <div>
            <img loading="lazy" src={booksThird} alt="images" width="242" height="375"
                />
        </div>
        <div>
            <img loading="lazy" src={booksFourth} alt="images" width="242" height="375"
                />
        </div>
        <div>
            <img loading="lazy" src={booksFive} alt="images" width="242" height="375"
                />
        </div>
        <div>
            <img loading="lazy" src={booksSix} alt="images" width="242" height="375"
                />
        </div>
        <div>
            <img loading="lazy" src={booksSeven} alt="images" width="242" height="375"
                />
        </div>
        <div>
            <img loading="lazy" src={booksEight} alt="images" width="242" height="375"
                />
        </div>
        <div>
            <img loading="lazy" src={booksNine} alt="images" width="242" height="375"
                />
        </div>
        <div>
            <img loading="lazy" src={booksTen} alt="images" width="242" height="375"
                />
        </div>
        <div>
            <img loading="lazy" src={booksEleven} alt="images" width="242" height="375"
                />
        </div>
        <div>
            <img loading="lazy" src={booksTwelve} alt="images" width="242" height="375"
                />
        </div>
        <div>
            <img loading="lazy" src={booksThirteen} alt="images" width="242" height="375"
                />
        </div>
        {/* <div>
            <img loading="lazy" src={booksFourteen} alt="images" width="242" height="375"
                />
        </div> */}
    </Slider>
  );
}