// EditingTab.js
import React from 'react';
import {
    teamFirst,
    teamSecond,
    teamThird,
    teamFourth,
} from '../assets/images'; // Import images

const EditingTab = () => {
  return (
    <div className="row">
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Jerry Ange"
              width="262"
              height="354"
              className="img-fluid"
              src={teamFirst}
            />
          </figure>
          <div className="overlay-text">
            <h4>Jerry Ange</h4>
            <p>Editor</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Lisa Schroder"
              width="262"
              height="354"
              className="img-fluid"
              src={teamSecond}
            />
          </figure>
          <div className="overlay-text">
            <h4>Lisa Schroder</h4>
            <p>Editor in Chief</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Michelle Hill"
              width="262"
              height="354"
              className="img-fluid"
              src={teamThird}
            />
          </figure>
          <div className="overlay-text">
            <h4>Michelle Hill</h4>
            <p>Editor</p>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6">
        <div className="teamBox">
          <figure>
            <img
              loading="lazy"
              alt="Steve Miller"
              width="262"
              height="354"
              className="img-fluid"
              src={teamFourth}
            />
          </figure>
          <div className="overlay-text">
            <h4>Steve Miller</h4>
            <p>Editorial-director</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditingTab;
